import React, { FC } from 'react';
import Container from '../../../UI/Container/Container';

import Button from '../../../UI/Button/Button';
import Emoji from '../../../UI/Emoji/Emoji';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import RegistrationModalWrapper from '../../../modals/RegistrationModalWrapper/RegistrationModalWrapper';

import { videoSrc } from '../WelcomeSection/constants';
import * as styles from './WelcomeSectionDesktop.module.scss';

import videoPreviewSrc from '../../../../images/components/pages/charity/WelcomeSection/video-preview.jpg';
import firstImageSrc from '../../../../images/components/pages/charity/WelcomeSectionDesktop/1.jpg';
import secondImageSrc from '../../../../images/components/pages/charity/WelcomeSectionDesktop/2.jpg';
import thirdImageSrc from '../../../../images/components/pages/charity/WelcomeSectionDesktop/3.jpg';

export interface WelcomeSectionDesktopProps {
    charityId: number;
}

const WelcomeSectionDesktop: FC<WelcomeSectionDesktopProps> = ({
    charityId,
}) => (
    <Section className={styles.root}>
        <Container className={styles.container}>
            <Stack spacing={55} fullWidth>
                <Typography variant="h2" responsive>
                    <Emoji name="waving-hand" /> Welcome to Ripples
                </Typography>
                <Stack spacing={16}>
                    <Typography variant="leadParagraph">
                        Ripples is a donation app built for donors.
                    </Typography>
                    <Typography variant="leadParagraph">
                        Here’s how it works:
                    </Typography>
                </Stack>
                <video
                    className={styles.video}
                    controls
                    poster={videoPreviewSrc}>
                    <source src={videoSrc} />
                </video>
                <div className={styles.grid}>
                    <div>
                        <Typography variant="h2">
                            It’s flexible giving
                        </Typography>
                        <Typography>
                            Pause or change your donations anytime, in a few
                            taps. Easy peasy.
                        </Typography>
                    </div>
                    <img alt="" src={firstImageSrc} />
                    <img alt="" src={secondImageSrc} />
                    <div>
                        <Typography variant="h2">Give pennies...</Typography>
                        <Typography>
                            Round up your card payments and donate pennies - up
                            to your chosen weekly limit
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h2">
                            Or a Weekly Donation
                        </Typography>
                        <Typography>
                            Donate every Monday but know you can pause or change
                            the amount anytime.
                        </Typography>
                    </div>
                    <img alt="" src={thirdImageSrc} />
                </div>
                <RegistrationModalWrapper modalProps={{ charityId }}>
                    {({ open }) => (
                        <Button size="big" onClick={open}>
                            Got it. Sign me up!
                        </Button>
                    )}
                </RegistrationModalWrapper>
            </Stack>
        </Container>
    </Section>
);

export default WelcomeSectionDesktop;
