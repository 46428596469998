import React, { FC } from 'react';

import { videoSrc } from './constants';
import * as styles from './HeroSection.module.scss';

import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import SignUpButton from '../../../UI/SignUpButton/SignUpButton';

import { Charity } from '../../../../../types/global';

export interface HeroSectionProps {
    id: Charity['id'];
    name: Charity['name'];
    headline: Charity['headline'];
    logo: Charity['logo'];
}

const HeroSection: FC<HeroSectionProps> = ({ id, name, headline, logo }) => (
    <Section className={styles.root} label="hero section">
        <Container className={styles.container}>
            <div className={styles.grid}>
                <div className={styles.header}>
                    <img
                        className={styles.logo}
                        alt={logo.alt}
                        src={logo.src}
                        width={56}
                        height={56}
                    />
                    <Typography
                        className={styles.title}
                        variant="display"
                        responsive>
                        Donate pennies to {name} with Ripples
                    </Typography>
                </div>
                <div className={styles.videoWrapper}>
                    <video
                        className={styles.video}
                        controls
                        // poster={videoPreviewImage}
                    >
                        <source src={videoSrc} />
                    </video>
                </div>
                <div className={styles.ctas}>
                    <Typography
                        className={styles.subtitle}
                        variant="leadParagraph"
                        responsive>
                        {name} is {headline}.
                    </Typography>
                    <SignUpButton charityId={id} />
                </div>
            </div>
        </Container>
    </Section>
);

export default HeroSection;
