import React, { FC } from 'react';

import List from '../../../UI/List/List';
import WelcomeSectionMobileBenefitsListItem from '../WelcomeSectionMobileBenefitsListItem/WelcomeSectionMobileBenefitsListItem';

const WelcomeSectionMobileBenefitsList: FC = () => (
    <List>
        <WelcomeSectionMobileBenefitsListItem
            header="Feel-good giving"
            description="Get push notifications that remind you of the difference you’re making"
        />
        <WelcomeSectionMobileBenefitsListItem
            header="Affordable"
            description="Small donations that your bank balance will barely notice"
        />
        <WelcomeSectionMobileBenefitsListItem
            header="Flexible"
            description="Set donation limits, pause or change your donations at anytime, in a few taps"
        />
    </List>
);

export default WelcomeSectionMobileBenefitsList;
