import React, { FC } from 'react';

import { Charity } from '../../../../../types/global';

import { textToTwoParts } from '../../../../utils/textToTwoParts';

import Button from '../../../UI/Button/Button';
import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
// import QuotesSlider from '../../../UI/QuotesSlider/QuotesSlider';
import RegistrationModalWrapper from '../../../modals/RegistrationModalWrapper/RegistrationModalWrapper';
import CharityCampaign from '../../../charity/CharityCampaign/CharityCampaign';
import CharityTags from '../../../charity/CharityTags/CharityTags';

import * as styles from './InfoSection.module.scss';

export interface InfoSectionProps {
    id: Charity['id'];
    name: Charity['name'];
    logo: Charity['logo'];
    headline: Charity['headline'];
    tags: Charity['tags'];
    cover: Charity['cover'];
    about: Charity['description'];
    quotes?: {
        quote: string;
        author: string;
    }[];
    video?: Charity['videoURL'];
    campaign?: Charity['campaign'];
}

const InfoSection: FC<InfoSectionProps> = ({
    id,
    name,
    logo,
    headline,
    tags,
    cover,
    about,
    quotes,
    video,
    campaign,
}) => {
    const hasVideo = !!video;
    const hasCampaign = !!campaign;
    const hasTags = tags.length !== 0;
    // const hasQuotes = quotes.length !== 0;
    const hasQuotes = false;

    const [aboutBodyFirstColumn, aboutBodySecondColumn] = textToTwoParts(about);

    return (
        <Section className={styles.root}>
            <Container>
                <Stack spacing={32} fullWidth>
                    <img
                        className={styles.logo}
                        src={logo.src}
                        alt={logo.alt}
                    />
                    <Typography
                        className={styles.headline}
                        variant="h2"
                        align="center"
                        responsive>
                        {name} is {headline}
                    </Typography>
                    {hasCampaign && (
                        <div className={styles.campaignWrapper}>
                            <CharityCampaign
                                title={campaign.title}
                                money={campaign.money}
                            />
                        </div>
                    )}
                    {hasTags && <CharityTags tags={tags} />}
                    {hasVideo ? (
                        <div className={styles.videoContainer}>
                            <iframe
                                title={`${name}-video`}
                                src={video}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    ) : (
                        <img
                            className={styles.cover}
                            alt={cover.alt}
                            src={cover.src}
                        />
                    )}
                    <div className={styles.aboutWrapper}>
                        {/* <Typography variant="h4" align="center">
                            {headline}
                        </Typography> */}
                        <div className={styles.aboutBody}>
                            <div>
                                <Typography paragraph>
                                    {aboutBodyFirstColumn}
                                </Typography>
                            </div>
                            <div>
                                <Typography paragraph>
                                    {aboutBodySecondColumn}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {/* {hasQuotes && <QuotesSlider quotes={quotes} />} */}
                    <RegistrationModalWrapper modalProps={{ charityId: id }}>
                        {({ open }) => (
                            <Button size="big" onClick={open}>
                                Support this charity
                            </Button>
                        )}
                    </RegistrationModalWrapper>
                </Stack>
            </Container>
        </Section>
    );
};

export default InfoSection;
