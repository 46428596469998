import React, { FC } from 'react';

import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import MediaAboutUsSlider from '../../../sliders/MediaAboutUsSlider/MediaAboutUsSlider';

import * as styles from './OurPromiseSection.module.scss';

import fundraisingRegulatorLogoSrc from '../../../../images/components/pages/charity/OurPromiseSection/fundraising-regulator.webp';

const OurPromiseSection: FC = () => (
    <Section className={styles.root}>
        <Container className={styles.container}>
            <Typography
                variant="h3"
                responsive
                align="center"
                className={styles.title}>
                Our promise
            </Typography>
            <Typography
                className={styles.subtitle}
                variant="leadParagraph"
                responsive
                align="center">
                Ripples is committed to maintaining the highest standards in
                fundraising and transparency.
            </Typography>
            <img
                className={styles.fundraisingRegulatorLogo}
                alt="registered with Fundraising Regulator"
                src={fundraisingRegulatorLogoSrc}
                width={253}
                height={78}
            />
            <MediaAboutUsSlider />
        </Container>
    </Section>
);

export default OurPromiseSection;
