import React, { FC } from 'react';

import ListItem from '../../../UI/ListItem/ListItem';
import Typography from '../../../UI/Typography/Typography';

import * as styles from './WelcomeSectionMobileBenefitsListItem.module.scss';

import GreenCheckIcon from '../../../../images/icons/green-check.svg';

export interface WelcomeSectionMobileBenefitsListItemProps {
    header: string;
    description: string;
}

const WelcomeSectionMobileBenefitsListItem: FC<
    WelcomeSectionMobileBenefitsListItemProps
> = ({ header, description }) => (
    <ListItem className={styles.root}>
        <div className={styles.iconWrapper}>
            <GreenCheckIcon width={20} height={20} alt="check icon" />
        </div>
        <Typography className={styles.header} variant="h4">
            {header}
        </Typography>
        <Typography
            className={styles.description}
            variant="bodySmall"
            paragraph>
            {description}
        </Typography>
    </ListItem>
);

export default WelcomeSectionMobileBenefitsListItem;
