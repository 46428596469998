import React, { FC } from 'react';

import { headerHeight } from '../../../../css/variables';

import Button from '../../../UI/Button/Button';
import Emoji from '../../../UI/Emoji/Emoji';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import RegistrationModalWrapper from '../../../modals/RegistrationModalWrapper/RegistrationModalWrapper';

import { videoSrc } from '../WelcomeSection/constants';
import WelcomeSectionMobileBenefitsList from '../WelcomeSectionMobileBenefitsList/WelcomeSectionMobileBenefitsList';

import * as styles from './WelcomeSectionMobile.module.scss';

import videoPreviewSrc from '../../../../images/components/pages/charity/WelcomeSection/video-preview.jpg';

export interface WelcomeSectionMobileProps {
    charityId: number;
    nextSectionElementRef: React.RefObject<HTMLElement>;
}

const WelcomeSectionMobile: FC<WelcomeSectionMobileProps> = ({
    charityId,
    nextSectionElementRef,
}) => {
    const onTellMeMoreButtonClick = () => {
        const nextSectionElement = nextSectionElementRef.current;
        if (nextSectionElement) {
            const nextSectionPosition =
                nextSectionElement.getBoundingClientRect().top;
            const offsetPosition =
                nextSectionPosition + window.pageYOffset - headerHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Section className={styles.root}>
            <Stack spacing={32} fullWidth>
                <Typography variant="h2" responsive align="center">
                    <Emoji name="waving-hand" /> Welcome to Ripples
                </Typography>
                <Typography align="center">
                    Ripples is a donation app built for donors. Here’s how it
                    works:
                </Typography>
                <video
                    className={styles.video}
                    controls
                    poster={videoPreviewSrc}>
                    <source src={videoSrc} />
                </video>
                <WelcomeSectionMobileBenefitsList />
                <Stack spacing={13} align="normal">
                    <RegistrationModalWrapper modalProps={{ charityId }}>
                        {({ open }) => (
                            <Button onClick={open}>
                                <Typography variant="bodySmall">
                                    Got it. Sign me up!
                                </Typography>
                            </Button>
                        )}
                    </RegistrationModalWrapper>
                    <Button color="white" onClick={onTellMeMoreButtonClick}>
                        <Typography variant="bodySmall">
                            Tell me more
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </Section>
    );
};

export default WelcomeSectionMobile;
