export const textToTwoParts = (text: string): [string, string] => {
    /*
        ------------------- Original text -------------------

        First sentence. Second sentence. Middle third 
        sentence. Next to middle fourth sentence.

        ----------------------- 1 ---------------------------
        ------------------- Two parts -----------------------

        First sentence. Second  |  sentence. Next to middle
        sentence. Middle third  |  fourth sentence.

        ----------------------- 2 ---------------------------
        ------- Two parts with a middle sentence ------------
        -------------- in a first column --------------------

        First sentence. Second  |  Next to middle fourth
        sentence. Middle third  |  sentence.
        sentence.               |

        ----------------------- 3 ---------------------------
        ------- Two parts with a middle sentence ------------
        ------------- in a second column --------------------

        First sentence. Second  |  Middle third sentence. 
        sentence.               |  Next to middle fourth
                                |  sentence.
        
        Output: 2nd or 3rd variant with a smallest length difference between parts
    */

    const middleSentenceIndex =
        text.slice(0, text.length / 2).lastIndexOf('.') + 1;
    const partsMiddleToFirstPart: [string, string] = [
        text.slice(0, middleSentenceIndex),
        text.slice(middleSentenceIndex),
    ];
    const partsMiddleToFirstPartLengthDiff = Math.abs(
        partsMiddleToFirstPart[1].length - partsMiddleToFirstPart[0].length,
    );

    const nextToMiddleSentenceIndex =
        text.length / 2 + text.slice(text.length / 2).indexOf('.') + 1;
    const partsMiddleToSecondPart: [string, string] = [
        text.slice(0, nextToMiddleSentenceIndex),
        text.slice(nextToMiddleSentenceIndex),
    ];
    const partsMiddleToLastPartLengthDiff = Math.abs(
        partsMiddleToSecondPart[1].length - partsMiddleToSecondPart[0].length,
    );

    if (partsMiddleToLastPartLengthDiff >= partsMiddleToFirstPartLengthDiff) {
        return partsMiddleToFirstPart;
    }

    return partsMiddleToSecondPart;
};
