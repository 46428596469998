import React, { FC, useRef } from 'react';

import useBreakpoints from '../../../../hooks/useBreakpoints';

import WelcomeSectionDesktop from '../WelcomeSectionDesktop/WelcomeSectionDesktop';
import WelcomeSectionMobile from '../WelcomeSectionMobile/WelcomeSectionMobile';
import WaysToDonateSection from '../WaysToDonateSection/WaysToDonateSection';

export interface WelcomeSectionProps {
    charityId: number;
}

const WelcomeSection: FC<WelcomeSectionProps> = ({ charityId }) => {
    const { isUp } = useBreakpoints();
    const waysToDonateSectionElementRef = useRef<HTMLElement>(null);

    if (isUp.maxTablet) return <WelcomeSectionDesktop charityId={charityId} />;

    return (
        <>
            <WelcomeSectionMobile
                charityId={charityId}
                nextSectionElementRef={waysToDonateSectionElementRef}
            />
            <WaysToDonateSection ref={waysToDonateSectionElementRef} />
        </>
    );
};

export default WelcomeSection;
