import React from 'react';
import { graphql, GetServerDataProps } from 'gatsby';

import SEO from '../../components/layout/SEO';
import Layout from '../../components/layout/Layout/Layout';
import HeroSection from '../../components/pages/charity/HeroSection/HeroSection';
import WelcomeSection from '../../components/pages/charity/WelcomeSection/WelcomeSection';
import OurPromiseSection from '../../components/pages/charity/OurPromiseSection/OurPromiseSection';
import InfoSection from '../../components/pages/charity/InfoSection/InfoSection';

import { Data } from './data';
import { getSchemaData } from './getSchemaData';

import { getCharity } from '../../api/charity/getCharity';
import { Charity } from '../../../types/global';

export async function getServerData({ pageContext }: GetServerDataProps) {
    const { id: charityId } = pageContext;
    const charity = await getCharity(charityId);

    return {
        props: charity,
    };
}

type Props = {
    data: Data;
    serverData: Charity;
};

const Template: React.FC<Props> = ({ data, serverData: charity }) => {
    const {
        markdownRemark: {
            frontmatter: {
                title,
                seoDescription,
                path,
                seoKeywords,
                allowIndexing,
            },
        },
    } = data;

    const schemaData = getSchemaData(data);

    return (
        <>
            <SEO
                title={title}
                description={seoDescription}
                path={path}
                image={undefined}
                keywords={seoKeywords}
                allowIndexing={allowIndexing}
                schemaData={schemaData}
                redirect={undefined}
            />
            <Layout>
                <HeroSection
                    id={charity.id}
                    name={charity.name}
                    headline={charity.headline}
                    logo={charity.logo}
                />
                <WelcomeSection charityId={charity.id} />
                <InfoSection
                    id={charity.id}
                    name={charity.name}
                    logo={charity.logo}
                    headline={charity.headline}
                    campaign={charity.campaign}
                    tags={charity.tags}
                    video={charity.videoURL}
                    cover={charity.cover}
                    about={charity.description}
                    // quotes={charity.quotes}
                />
                <OurPromiseSection />
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            timeToRead
            frontmatter {
                allowIndexing
                date
                path
                title
                seoDescription
                seoKeywords
            }
        }
        currentBuildDate {
            currentDate
        }
    }
`;

export default Template;
