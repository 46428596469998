import { Data } from './data';

export const getSchemaData = ({ markdownRemark, currentBuildDate }: Data) => {
    const { frontmatter } = markdownRemark;
    const { currentDate } = currentBuildDate;

    return [
        {
            '@context': 'https://schema.org',
            '@graph': [
                {
                    '@type': 'Organization',
                    '@id': 'https://www.joinripples.org/#organization',
                    name: 'Ripples',
                    url: 'https://www.joinripples.org/',
                    sameAs: [],
                    logo: {
                        '@type': 'ImageObject',
                        '@id': 'https://www.joinripples.org/#logo',
                        url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                        width: 500,
                        height: 500,
                        caption: 'Ripples',
                    },
                    image: {
                        '@id': 'https://www.joinripples.org/#logo',
                    },
                },
                {
                    '@type': 'WebSite',
                    '@id': 'https://www.joinripples.org/#website',
                    url: 'https://www.joinripples.org/',
                    name: 'Ripples',
                    description: 'Donate pennies as you spend',
                    publisher: {
                        '@id': 'https://www.joinripples.org/#organization',
                    },
                },
                {
                    '@type': 'WebPage',
                    '@id': 'https://www.joinripples.org/#webpage',
                    url: `https://www.joinripples.org${frontmatter.path}`,
                    inLanguage: 'en-GB',
                    name: `With Ripples you can donate pennies to ${frontmatter.charityName}`,
                    isPartOf: {
                        '@id': 'https://www.joinripples.org/#website',
                    },
                    about: {
                        '@id': 'https://www.joinripples.org/#organization',
                    },
                    datePublished: frontmatter.date,
                    dateModified: currentDate,
                },
            ],
        },
    ];
};
