import React from 'react';

import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import WaysToDonate from '../../../charity/WaysToDonate/WaysToDonate';

import * as styles from './WaysToDonateSection.module.scss';

const WaysToDonateSection = React.forwardRef<HTMLElement>((_props, ref) => (
    <Section ref={ref} className={styles.root}>
        <Container className={styles.container}>
            <Stack spacing={32} valign="top">
                <Typography
                    className={styles.title}
                    variant="h2"
                    align="center">
                    There are two ways to donate
                </Typography>
                <WaysToDonate />
            </Stack>
        </Container>
    </Section>
));

export default WaysToDonateSection;
